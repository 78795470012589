import React, { useCallback } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Linkedlin from "../../images/LinkedIn-logo.png";
import './Footer.css';
import whatsapp from '../../images/whatsapp.png';
import location from '../../images/location.png';
import mail from '../../images/mail.png';
import FB from '../../images/FB.png';
import insta_logo from '../../images/insta-logo.png';
import Youtube_logo from '../../images/Youtube-logo.png';

const Footer = () => {
  const openInNewTab = useCallback((url) => {
    window.open(url, '_blank');
  }, []);

  return (
    <footer className="py-1">
      <Container className="mx-auto mt-5 mb-5">
        <Row className="text-center mt-3">
          <h4>Get in Touch</h4>
        </Row>
        <Row className="text-center mx-0">
          <Col xs={12} md={4} className="dashed-line">
            <a
              href="tel:+91 8928873775"
              className="footer-link"
              onClick={() => openInNewTab('tel:+91 8928873775')}
            >
              <img
                src={whatsapp}
                loading="lazy"
                alt="Phone"
                className="mr-2 icon-img"
              />
              <p className="footer-text">+91 8928873775</p>
            </a>
          </Col>
          <Col xs={12} md={4} className="dashed-line">
            <a
              href=" "
              className="footer-link"
              onClick={() => openInNewTab('https://www.google.com/maps?q=Vista Core Building, Plot no 29 Talera Park Society, Kalyani Nagar, Pune, Maharashtra 411006')}
            >
              <img
                src={location}
                loading="lazy"
                alt="location"
                className="mr-2 icon-img"
              />
              <p className="footer-text">Vista Core Building, Plot no 29 Talera Park Society, Kalyani Nagar, Pune, Maharashtra 411006</p>
            </a>
          </Col>
          <Col xs={12} md={4}>
            <a
              href="mailto:info@fis-pune.com"
              className="footer-link"
              onClick={() => openInNewTab('mailto:info@fis-pune.com')}
            >
              <img
                src={mail}
                loading="lazy"
                alt="mail"
                className="mr-2 icon-img"
              />
              <p className="footer-text">info@fis-pune.com</p>
            </a>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12} className="social-icons text-center">
            <a href="https://www.facebook.com/FinlandInternationalSchoolPune" className="footer-link" onClick={() => openInNewTab('https://www.facebook.com/FinlandInternationalSchoolPune')} target="_blank" rel="noopener noreferrer">
              <img
                src={FB}
                loading="lazy"
                alt="Facebook"
                className="mr-5 logo-img"
              />
            </a>

            <a href="https://www.instagram.com/fis_pune/" className="footer-link" onClick={() => openInNewTab('https://www.instagram.com/fis_pune/')} target="_blank" rel="noopener noreferrer">
              <img
                src={insta_logo}
                loading="lazy"
                alt="Instagram"
                className="mr-3 logo-img"
              />
            </a>
            <a href="https://www.linkedin.com/company/fis-pune/about/" className="footer-link" onClick={() => openInNewTab('https://www.linkedin.com/company/fis-pune/about/')} target="_blank" rel="noopener noreferrer">
              <img
                src={Linkedlin}
                loading="lazy"
                alt="LinkedIn"
                className="mr-3 logo-img"
              />
            </a>
            <a href="https://www.youtube.com/@FinlandInternationalSchool663" className="footer-link" onClick={() => openInNewTab('https://www.youtube.com/@FinlandInternationalSchool663')} target="_blank" rel="noopener noreferrer">
              <img
                src={Youtube_logo}
                loading="lazy"
                alt="YouTube"
                className="logo-img"
              />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
