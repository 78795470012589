import React, { useState, useMemo, useCallback } from 'react';
import { Tab, Nav, Col, Row } from 'react-bootstrap';
import './FinnishEdge.css';
import { Animate } from '../Animate/Animate';
import finnish_edge from '../../images/finish-edge.webp';
import Collaborative_Learning from '../../images/Collaborative Learning Environment.webp';
import Play_based from '../../images/Play-based and Experiential Learning.webp';
import Transversal from '../../images/Transversal Competences.webp';
import Research_based from '../../images/Research-Based Approach.webp';

const FinnishEdge = () => {
    const [activeTab, setActiveTab] = useState('images');

    // Memoized image sources
    const imageSources = useMemo(
        () => ({
            finnish_edge,
            Play_based,
            Collaborative_Learning,
            Transversal,
            Research_based,
        }),
        []
    );

    // Memoized callback for setActiveTab
    const handleTabChange = useCallback((key) => {
        setActiveTab(key);
    }, []);

    return (
        <div className='finish-container finnish-edge-container'>
            <h4 className="title-finnish" >
                THE FINNISH EDGE
            </h4>
            <div className='d-flex justify-content-center'>
                <Animate />
            </div>
            <div className='container text-center mt-5 mx-auto'>
                <Tab.Container id='edge-tabs' activeKey={activeTab} onSelect={handleTabChange}>
                    <Row>
                        <Col md={12}>
                            <Nav variant='tabs' className='finish-nav-menu'>
                                <Nav.Item className={activeTab === 'images' ? 'active' : ''}>
                                    <Nav.Link eventKey='images'>Unique Finnish <br /> Learning Methodology</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className={activeTab === 'PlayBased' ? 'active' : ''}>
                                    <Nav.Link eventKey='PlayBased'>Play-based and <br /> Experimental Learning</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className={activeTab === 'collaborative' ? 'active' : ''}>
                                    <Nav.Link eventKey='collaborative'>Collaborative <br /> Learning Environment</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className={activeTab === 'transversal' ? 'active' : ''}>
                                    <Nav.Link eventKey='transversal'>Transversal <br /> Competences</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className={activeTab === 'research' ? 'active' : ''}>
                                    <Nav.Link eventKey='research'>Research-Based <br /> Approach</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                    </Row>
                    <Tab.Content className='tab-content'>
                        <Tab.Pane eventKey='images' className={activeTab === 'images' ? 'active' : ''}>
                            <div className='finnish-edge-content p-5 rounded d-md-flex nav-content-div'>

                                <ul>
                                    <li>
                                        Personalised learning experience, fostering a love for education that lasts a lifetime
                                    </li>
                                    <li>
                                        Students actively participate in their own learning, seeking and reflecting on their goals
                                    </li>
                                    <li >
                                        Students collaborate with teachers and guardians to create a <strong> Personal Learning Plan (PLPs)</strong> focusing on strengths and areas for improvement
                                    </li>
                                </ul>
                                <div className='finish-card-image'>
                                    <img
                                        src={imageSources.finnish_edge}
                                        alt='finnish_edge'
                                        loading="lazy"
                                        width='28.75rem'
                                        height='26.375rem'
                                        className='img-fluid content-image'
                                    />
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey='PlayBased' className={activeTab === 'PlayBased' ? 'active' : ''}>
                            <div className=" finnish-edge-content p-5 rounded d-md-flex nav-content-div" >
                                <ul>
                                    <li>
                                        <strong>Vibrant Learning Environment:</strong> Our classrooms are dynamic spaces where play is the cornerstone of education.
                                    </li>
                                    <li>Learning beyond textbooks, encouraging and creative exploration.
                                    </li>
                                    <li>
                                        Emphasis on experiential learning to develop deep understanding, problem-solving skills, and critical thinking.
                                    </li>
                                    <li >
                                        An approach that fosters creativity, innovation, and prepares students for an ever-evolving world.
                                    </li>
                                </ul>
                                <div className="finish-card-image" >
                                    <img
                                        src={imageSources.Play_based}
                                        loading="lazy"
                                        width="82.063rem"
                                        height="54.688rem"
                                        alt="Play_based"
                                        className="img-fluid content-image" />
                                </div>
                            </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey='collaborative' className={activeTab === 'collaborative' ? 'active' : ''}>
                            <div className=" finnish-edge-content p-5 rounded d-md-flex nav-content-div" >
                                <ul>
                                    <li>
                                        Going beyond nurturing academic excellence to hone vital life skills such as teamwork, communication, and social development.

                                    </li>
                                    <li>
                                        Collaborative projects, encouraging  brainstorming and effective communication.

                                    </li>
                                    <li >
                                        Learning not just from teachers but also from each other, enriching their educational journey.
                                    </li>
                                </ul>
                                <div className="finish-card-image" >
                                    <img
                                        src={imageSources.Collaborative_Learning}
                                        loading="lazy"
                                        alt="Collaborative_Learning"
                                        className="img-fluid content-image" />
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey='transversal' className={activeTab === 'transversal' ? 'active' : ''}>
                            <div className=" finnish-edge-content p-5 rounded d-md-flex nav-content-div" >
                                <ul>
                                    <li>
                                        A curriculum that focuses on transversal competences that extend beyond traditional academic knowledge.
                                    </li>
                                    <li>
                                        Cultivating lifelong learners who can adapt, innovate, and pursue self-directed learning.
                                    </li>
                                    <li >
                                        Foster global perspectives while celebrating India's rich cultural .
                                    </li>
                                    <li>Prioritise mental and physical well-being, time management, and other life skills.
                                    </li>
                                    <li>Ensures proficiency in various forms of communication and mastery of technology.
                                    </li>
                                </ul>
                                <div className="finish-card-image" >
                                    <img
                                        src={Transversal}
                                        loading="lazy"
                                        alt="Transversal"
                                        className="img-fluid content-image" />
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey='research' className={activeTab === 'research' ? 'active' : ''}>
                            <div className=" finnish-edge-content p-5 rounded d-md-flex nav-content-div" >
                                <ul>
                                    <li>
                                        <strong>Quality Assurance Partner:</strong> Collaboration with EduCluster Finland (ECF), a part of the University of Jyvaskyla Group, to co-develop innovative educational solutions based on the latest educational research.
                                    </li>
                                    <li>
                                        <strong>Expert Finnish Teachers:</strong> An academic team of expert teachers and mentors from Finland who work closely with students
                                    </li>
                                    <li >
                                        Students collaborate with teachers and guardians to create a Personal Learning Plan (PLPs) focusing on strengths and areas for improvement
                                    </li>
                                </ul>
                                <div className="finish-card-image" >
                                    <img
                                        src={imageSources.Research_based}
                                        loading="lazy"
                                        alt="Research_based"
                                        className="img-fluid content-image" />
                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div >
    );
};

export default FinnishEdge;
