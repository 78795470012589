// HomeSection.js
import { useState, useMemo } from "react";
import React from "react";
import "./WhoWeAre.css";
import { Card } from "react-bootstrap";
import { Animate } from "../Animate/Animate";
import who_we_are from "../../images/who-we-are.webp";

const WhoWeAre = () => {
  const [accordionState, setAccordionState] = useState({
    eduCluster: true,
    leadership: false,
    finnishApproach: false,
  });

  const handleAccordionToggle = (accordionId) => {
    setAccordionState((prevState) => ({
      ...prevState,
      [accordionId]: !prevState[accordionId],
    }));
  };

  const [truncateContent, setTruncateContent] = useState({
    eduCluster: true,
    leadership: true,
    finnishApproach: true,
  });
  const handleReadMoreToggle = (accordionId) => {
    setTruncateContent((prev) => ({
      ...prev,
      [accordionId]: !prev[accordionId],
    }));
  };

  const memoizedImage = useMemo(
    () => (
      <img
        src={who_we_are}
        alt="Who we areImage"
        width="528"
        height="468"
        loading="lazy"
      />
    ),
    []
  );
  return (
    <>
      <section className="extra-section " id="whoweare">
        <div className="container">
          <div className="row justify-content-center text-center top-0 start-50 ">
            <div className="col-md-10">
              <div className="text-overlay position-absolute mt-3">
                <h4>WHO WE ARE</h4>
                <div className="centered-container">
                  <Animate />
                </div>

                <h5>
                  Finland International School (FIS) Pune is owned and operated
                  by Goenka Global Education <br /> and proud to collaborate
                  with our quality partner EduCluster Finland, an <br />{" "}
                  education export company, and a part of University of
                  Jyvaskyla Group.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faq-style-1 p-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <Card
                className="card w-100 p-3 mb-4 "
                style={{ backgroundColor: "#c6c6c6", width: "720px" }}
              >
                <div className="accordion m-2 " id="accordionExample">
                  {/* EduCluster Finland Accordion Item */}
                  <div className="accordion-item m-3">
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button ${
                          accordionState.eduCluster ? "" : "collapsed"
                        }`}
                        type="button"
                        onClick={() => handleAccordionToggle("eduCluster")}
                        aria-expanded={
                          accordionState.eduCluster ? "true" : "false"
                        }
                        aria-controls="collapseOne"
                      >
                        <strong>EduCluster Finland</strong>
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className={`accordion-collapse collapse ${
                        accordionState.eduCluster ? "show" : ""
                      }`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        {truncateContent.eduCluster ? (
                          <>
                            <h6>
                              FIS PUNE IS AMONG THE FIRST SCHOOLS IN THE COUNTRY
                              TO OFFER THE FINNISH WAY OF LEARNING.
                            </h6>
                            <p>In partnership with EduCluster Finland (ECF)</p>
                            <p>
                              EduCluster Finland (ECF), University of Jyvaskyla
                              Group, co-develops innovative educational
                              solutions with its three owners: the University
                              of Jyvaskyla, JAMK University of Applied Sciences,
                              and Jyvaskyla Educational Consortium Gradia.{" "}
                            </p>
                            <p>{accordionState.eduCluster ? "" : ""}</p>
                            <div className="read-more">
                              <button
                                className="read-more-btn"
                                onClick={() =>
                                  handleReadMoreToggle("eduCluster")
                                }
                              >
                                Read More
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <p>
                              ECF has vast experience in reinventing the Finnish
                              education concept to complement contexts and local
                              needs worldwide.
                            </p>
                            <p>
                              EduCluster Finland has wide-ranging experience in
                              co-creating inspirational, functional, and
                              sustainable international learning communities
                              with partners across the globe. Through these
                              extensive partnerships, EduCluster Finland has
                              pinpointed defining elements of Finland’s
                              education concept that complement other education
                              systems.{" "}
                            </p>
                            <div className="read-more">
                              <button
                                className="read-more-btn"
                                onClick={() =>
                                  handleReadMoreToggle("eduCluster")
                                }
                              >
                                Read Less{" "}
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Finnish Approach Accordion Item */}
                  <div className="accordion-item m-3">
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button ${
                          accordionState.finnishApproach ? "" : "collapsed"
                        }`}
                        type="button"
                        onClick={() => handleAccordionToggle("finnishApproach")}
                        aria-expanded={
                          accordionState.finnishApproach ? "true" : "false"
                        }
                        aria-controls="collapseThree"
                      >
                        <strong>Finnish Approach</strong>
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className={`accordion-collapse collapse ${
                        accordionState.finnishApproach ? "show" : ""
                      }`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        {truncateContent.finnishApproach ? (
                          <>
                            <h5>Respect for diversity</h5>
                            <p>
                              {" "}
                              We are different yet we are the same. Let’s
                              embrace this.
                            </p>
                            <h5>Responsibility for learning</h5>
                            <p>
                              We are all learners for life. We trust the
                              professionalism of our teachers, and we trust our
                              students to be proactive learners.
                            </p>

                            <p>{accordionState.finnishApproach ? "" : ""}</p>
                            <div className="read-more">
                              <button
                                className="read-more-btn"
                                onClick={() =>
                                  handleReadMoreToggle("finnishApproach")
                                }
                              >
                                Read More{" "}
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <h5>Courage to be creative</h5>
                            <p>
                              To be a pioneer is to take educated risks. To be
                              an innovator is to use your imagination.
                            </p>
                            <h5>Collaboration for shared success</h5>
                            <p>Working together works.</p>
                            <h5>Global citizenship</h5>
                            <p>
                              We are citizens of the world, confidently
                              navigating our own path to reshape the future.
                            </p>
                            <div className="read-more">
                              <button
                                className="read-more-btn"
                                onClick={() =>
                                  handleReadMoreToggle("finnishApproach")
                                }
                              >
                                Read Less{" "}
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div className="col-lg-6">
              <div className="who-we-are-img d-none d-lg-block">
                <div className="image-card">{memoizedImage}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhoWeAre;
